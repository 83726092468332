import React from "react";
import { ConversationalForm } from "conversational-form";
import axios from "axios";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.formFields = [
      {
        tag: "input",
        type: "radio",
        name: "termenii",
        "cf-questions":
          "Pentru a continua programarea este nevoie de acordul tau privind colectarea datelor cu caracter personal. Termenii si conditiile prelucrarii datelor le poti gasi in Politica de confidentialitate. Prin apasarea butonului, esti de acord ca Start Finance sa iti prelucreze datele cu caracter personal in conformitate cu prevederile Regulamentului general privind protectia datelor (UE) 679/2016.",
        "cf-label": "Continua",
        value: "Continua[De Acord]",
      },
      {
        tag: "input",
        type: "radio",
        name: "credit",
        "cf-questions":
          "Consideram ca timpul este cea mai pretioasa resursa, asa ca pentru a fi eficienti dorim sa ne facem temele inainte de intalnire. Pentru a face acest lucru, te rog sa ne spui care este facilitatea de creditare despre care doresti sa discutam",
        "cf-label": "Ipotecar",
        value: "Ipotecar",
      },
      {
        tag: "input",
        type: "radio",
        name: "credit",
        "cf-label": "Nevoi personale",
        value: "Nevoi Personale",
      },
      {
        tag: "input",
        type: "radio",
        name: "credit",
        "cf-label": "Refinantare credit ipotecar",
        value: "Refinantare credit ipotecar",
      },
      {
        tag: "input",
        type: "radio",
        name: "credit",
        "cf-label": "Refinantare credit nevoi personale",
        value: "Refinantare credit nevoi personale",
      },
      {
        tag: "input",
        type: "radio",
        name: "credit",
        "cf-label": "Refinantare carduri de credit / overdrafturi",
        value: "Refinantare carduri de credit / overdrafturi",
      },
      {
        tag: "input",
        type: "text",
        name: "nume",
        pattern: "[a-zA-z]{3,}",
        required: true,
        "cf-questions": "Te rugam sa ne ajuti cu numele tau",
        "cf-error":
          "Numele trebuie sa fie de cel putin 3 litere, si sa nu fie gol campul.",
        "cf-input-placeholder": "Exemplu: Ioana",
      },
      {
        tag: "input",
        type: "text",
        name: "prenume",
        pattern: "[a-zA-z]{3,}",
        required: true,
        "cf-questions": "Avem nevoie si de prenumele tau",
        "cf-error":
          "Prenumele trebuie sa fie de cel putin 3 litere, si sa nu fie gol campul.",
        "cf-input-placeholder": "Exemplu: Corches",
      },
      {
        tag: "input",
        type: "text",
        name: "telefon",
        pattern: "[0-9]",
        required: true,
        "cf-error": "Va rugam sa introduceti un telefon valid.",
        "cf-questions": "Numarul de telefon",
        "cf-input-placeholder": "Exemplu: +40 000 000 000",
      },
      {
        tag: "input",
        type: "email",
        name: "email",
        required: true,
        "cf-error": "Va rugam sa introduceti un email valid.",
        "cf-questions": "Emailul tau",
        "cf-input-placeholder": "Exemplu: contact@mail.com",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-questions": "Luna pentru programare",
        "cf-label": "Ianuarie",
        value: "Ianuarie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Februarie",
        value: "Februarie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Martie",
        value: "Martie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Aprilie",
        value: "Aprilie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Mai",
        value: "Mai",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Iunie",
        value: "Iunie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Iulie",
        value: "Iulie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "August",
        value: "August",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Septembrie",
        value: "Septembrie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Octombrie",
        value: "Octombrie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Noiembrie",
        value: "Noiembrie",
      },
      {
        tag: "input",
        type: "radio",
        name: "luna",
        "cf-label": "Decembrie",
        value: "Decembrie",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-questions": "Ziua pentru programare",
        "cf-label": "01",
        value: "01",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "02",
        value: "02",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "03",
        value: "03",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "04",
        value: "04",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "05",
        value: "05",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "06",
        value: "06",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "07",
        value: "07",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "08",
        value: "08",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "09",
        value: "09",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "10",
        value: "10",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "11",
        value: "1",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "12",
        value: "12",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "13",
        value: "13",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "14",
        value: "14",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "15",
        value: "15",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "16",
        value: "16",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "17",
        value: "17",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "18",
        value: "18",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "19",
        value: "19",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "20",
        value: "20",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "21",
        value: "21",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "22",
        value: "22",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "23",
        value: "23",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "24",
        value: "24",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "25",
        value: "25",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "26",
        value: "26",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "27",
        value: "27",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "28",
        value: "28",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "29",
        value: "29",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "30",
        value: "30",
      },
      {
        tag: "input",
        type: "radio",
        name: "ziua",
        "cf-label": "31",
        value: "31",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-questions": "Ora pentru programare",
        "cf-label": "00:00",
        value: "00:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "01:00",
        value: "01:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "02:00",
        value: "02:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "03:00",
        value: "03:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "04:00",
        value: "04:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "05:00",
        value: "05:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "06:00",
        value: "06:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "07:00",
        value: "07:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "08:00",
        value: "08:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "09:00",
        value: "09:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "10:00",
        value: "10:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "11:00",
        value: "11:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "12:00",
        value: "12:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "13:00",
        value: "13:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "14:00",
        value: "14:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "15:00",
        value: "15:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "16:00",
        value: "16:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "17:00",
        value: "17:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "18:00",
        value: "18:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "19:00",
        value: "19:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "20:00",
        value: "20:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "21:00",
        value: "21:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "22:00",
        value: "22:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "ora",
        "cf-label": "23:00",
        value: "23:00",
      },
      {
        tag: "input",
        type: "radio",
        name: "metoda_meeting",
        "cf-questions":
          "Ce aplicatie doresti sa folosim pentru intalnirea online?",
        "cf-label": "Skype",
        value: "Skype",
      },
      {
        tag: "input",
        type: "radio",
        name: "metoda_meeting",
        "cf-label": "Signal",
        value: "Signal",
      },
      {
        tag: "input",
        type: "radio",
        name: "metoda_meeting",
        "cf-label": "WhatsApp",
        value: "Whatsapp",
      },
      {
        tag: "input",
        type: "radio",
        name: "metoda_meeting",
        "cf-label": "Microsoft Teams",
        value: "Microsoft Teams",
      },
      {
        tag: "input",
        type: "radio",
        name: "confirmare_date",
        "cf-questions":
          "Va rugam sa verificati datele introduse ca sa se evite erorile.",
        "cf-label": "Datele introduse sunt corecte, programez o intalnire",
        value: "Date corecte confirmate",
      },
    ];

    this.submitCallback = this.submitCallback.bind(this);
  }

  componentDidMount() {
    this.cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback: this.submitCallback,
        preventAutoFocus: true,
        robotImage:
          "https://startfinance.ro/wp-content/uploads/2021/02/Untitled-3.png",
        hideUserInputOnNoneTextInput: true,
        // loadExternalStyleSheet: false
      },
      tags: this.formFields,
    });
    this.elem.appendChild(this.cf.el);
  }

  submitCallback() {
    var formDataSerialized = this.cf.getFormData(true);
    axios
      .get(
        `https://startfinance.ro/mail.php?tip=long_form&date_confirmate=${formDataSerialized.termenii}&credit=${formDataSerialized.credit}&email=${formDataSerialized.email}&luna=${formDataSerialized.luna}&metoda_meeting=${formDataSerialized.metoda_meeting}&nume=${formDataSerialized.nume}&ora=${formDataSerialized.ora}&prenume=${formDataSerialized.prenume}&telefon=${formDataSerialized.telefon}&termenii=${formDataSerialized.termenii}&ziua=${formDataSerialized.ziua}`,
        {
        },
        {
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "Token",
          "Access-Control-Allow-Origin": "*",
        }
      )
      .then((result) => {
        this.cf.addRobotChatResponse(
          "Iti multumim pentru informatiile furnizate si pentru timpul acordat. Ramane sa ne vedem in cadrul intalnirii online stabilite, unde un consultant Start Finance iti va raspunde la toate intrebarile si iti va prezenta variantele de creditare disponibile. Cu 24h inainte de intalnire iti vom trimite pe email un link al aplicatiei pe care doresti sa o folosim."
        );
      })
      .catch((error) => {
        this.cf.addRobotChatResponse(
          "Din pacate a intervenit o eroare la primirea datelor. Va rugam sa incercati din nou."
        );
      });
  }

  render() {
    return (
      <div>
        <div ref={(ref) => (this.elem = ref)} />
      </div>
    );
  }
}
